import slc1 from '../../_assets/images/gallery/slc/slc-1.png'
import slc2 from '../../_assets/images/gallery/slc/slc-2.png'
import slc3 from '../../_assets/images/gallery/slc/slc-3.png'
import slc4 from '../../_assets/images/gallery/slc/slc-4.png'
import slc5 from '../../_assets/images/gallery/slc/slc-5.png'
import slc6 from '../../_assets/images/gallery/slc/slc-6.png'
import slc7 from '../../_assets/images/gallery/slc/slc-7.png'
import slc8 from '../../_assets/images/gallery/slc/slc-8.png'
import slc9 from '../../_assets/images/gallery/slc/slc-9.png'
import slc10 from '../../_assets/images/gallery/slc/slc-10.png'
import slc11 from '../../_assets/images/gallery/slc/slc-11.png'
import slc12 from '../../_assets/images/gallery/slc/slc-12.png'
import slc13 from '../../_assets/images/gallery/slc/slc-13.png'
import slc14 from '../../_assets/images/gallery/slc/slc-14.png'

import llv1 from '../../_assets/images/gallery/lake-level-viewer/llv-1.png'
import llv2 from '../../_assets/images/gallery/lake-level-viewer/llv-2.png'
import llv3 from '../../_assets/images/gallery/lake-level-viewer/llv-3.png'
import llv4 from '../../_assets/images/gallery/lake-level-viewer/llv-4.png'
import llv5 from '../../_assets/images/gallery/lake-level-viewer/llv-5.png'
import llv6 from '../../_assets/images/gallery/lake-level-viewer/llv-6.png'
import llv7 from '../../_assets/images/gallery/lake-level-viewer/llv-7.png'
import llv8 from '../../_assets/images/gallery/lake-level-viewer/llv-8.png'
import llv9 from '../../_assets/images/gallery/lake-level-viewer/llv-9.png'
import llv10 from '../../_assets/images/gallery/lake-level-viewer/llv-10.png'
import llv11 from '../../_assets/images/gallery/lake-level-viewer/llv-11.png'
import llv12 from '../../_assets/images/gallery/lake-level-viewer/llv-12.png'
import llv14 from '../../_assets/images/gallery/lake-level-viewer/llv-14.png'

import gi1 from '../../_assets/images/gallery/green-infra/gi-1.png'
import gi2 from '../../_assets/images/gallery/green-infra/gi-2.png'
import gi3 from '../../_assets/images/gallery/green-infra/gi-3.png'
import gi4 from '../../_assets/images/gallery/green-infra/gi-4.png'
import gi5 from '../../_assets/images/gallery/green-infra/gi-5.png'

import enow1 from '../../_assets/images/gallery/enow/enow-1.png'
import enow2 from '../../_assets/images/gallery/enow/enow-2.png'
import enow3 from '../../_assets/images/gallery/enow/enow-3.png'
import enow4 from '../../_assets/images/gallery/enow/enow-4.png'
import enow5 from '../../_assets/images/gallery/enow/enow-5.png'
import enow6 from '../../_assets/images/gallery/enow/enow-6.png'

import kl1 from '../../_assets/images/gallery/kloudlog/kl-1.png'

import wyld1 from "../../_assets/images/gallery/wyldgreens/wyld-1.png"
import wyld2 from "../../_assets/images/gallery/wyldgreens/wyld-2.png"
import wyld3 from "../../_assets/images/gallery/wyldgreens/wyld-3.png"
import wyld4 from "../../_assets/images/gallery/wyldgreens/wyld-4.png"
import wyld5 from "../../_assets/images/gallery/wyldgreens/wyld-5.png"
import wyld6 from "../../_assets/images/gallery/wyldgreens/wyld-6.png"

import lld1 from "../../_assets/images/gallery/loose-leash-dog/lld-1.png"
import lld2 from "../../_assets/images/gallery/loose-leash-dog/lld-2.png"
import lld3 from "../../_assets/images/gallery/loose-leash-dog/lld-3.png"
import lld4 from "../../_assets/images/gallery/loose-leash-dog/lld-4.png"
import lld5 from "../../_assets/images/gallery/loose-leash-dog/lld-4.png"
import lld6 from "../../_assets/images/gallery/loose-leash-dog/lld-6.png"

import lumber1 from "../../_assets/images/gallery/lumber/lj-1.png"
import lumber2 from "../../_assets/images/gallery/lumber/lj-2.png"
import lumber3 from "../../_assets/images/gallery/lumber/lj-3.png"
import lumber4 from "../../_assets/images/gallery/lumber/lj-4.png"
import lumber5 from "../../_assets/images/gallery/lumber/lj-5.png"
import lumber6 from "../../_assets/images/gallery/lumber/lj-6.png"
import lumber7 from "../../_assets/images/gallery/lumber/mobile.png"

import cs1 from '../../_assets/images/gallery/codestasher/cs-1.png'
import cs2 from '../../_assets/images/gallery/codestasher/cs-2.png'
import cs3 from '../../_assets/images/gallery/codestasher/cs-3.png'
import cs4 from '../../_assets/images/gallery/codestasher/cs-4.png'
import cs5 from '../../_assets/images/gallery/codestasher/cs-5.png'


import hal1 from '../../_assets/images/gallery/hal/hal-1.png'
import hal2 from '../../_assets/images/gallery/hal/hal-2.png'
import hal3 from '../../_assets/images/gallery/hal/hal-3.png'
import hal4 from '../../_assets/images/gallery/hal/hal-4.png'

import is1 from '../../_assets/images/gallery/infostasher/is-1.png'
import is2 from '../../_assets/images/gallery/infostasher/is-2.png'
import is3 from '../../_assets/images/gallery/infostasher/is-3.png'
import is4 from '../../_assets/images/gallery/infostasher/is-4.png'
import is5 from '../../_assets/images/gallery/infostasher/is-5.png'
import is6 from '../../_assets/images/gallery/infostasher/is-6.png'
import is7 from '../../_assets/images/gallery/infostasher/is-7.png'


import ec1 from "../../_assets/images/gallery/elephant-chart/ec-1.png"
import ec2 from "../../_assets/images/gallery/elephant-chart/ec-2.png"
import ec3 from "../../_assets/images/gallery/elephant-chart/ec-3.png"
import ec4 from "../../_assets/images/gallery/elephant-chart/ec-4.png"

import dt1 from '../../_assets/images/gallery/delta-trades/dt-3.png'
import dt2 from '../../_assets/images/gallery/delta-trades/dt-1.png'
import dt3 from '../../_assets/images/gallery/delta-trades/dt-2.png'

import mt1 from "../../_assets/images/gallery/music-trainer/mt-1.png"
import mt2 from "../../_assets/images/gallery/music-trainer/mt-2.png"
import mt3 from "../../_assets/images/gallery/music-trainer/mt-3.png"
import mt4 from "../../_assets/images/gallery/music-trainer/mt-4.png"

import orb1 from '../../_assets/images/gallery/orbiter/orb-1.png'

import tdd1 from "../../_assets/images/gallery/logger/tdd-1.png"
import tdd2 from "../../_assets/images/gallery/logger/tdd-2.png"
import tdd3 from "../../_assets/images/gallery/logger/tdd-3.png"

export const galleryConfig = {
    professional: [
        {
            title: `NOAA Sea Level Calculator`,
            tag: 'slrc',
            description: `A joint project between NOAA, NASA, and the USGS, the Sea Level Calculator is a tool that allows users to look at historic measurements of over 100 years 
                        from some tide stations, displays projection data, extreme water events, and flood levels on roughly 25 different infographics and maps. The user can simulate 
                        various levels of flooding above high-tide, view data based on 1 degree grid cells, and download reports of the data.`,
            shortDescription: `A joint project between NOAA, NASA, and the USGS, the Sea Level Calculator is a tool used by climatologists, civil engineers, and city planners 
                        to chart projected sea level measurements and trends for the continental United States.`,
            listItems: [
                `Lead Developer`,
                `5 tools in one`,
                `Interagency tool`,
                `Uses data measured from orbit by NASA`
            ],
            liveLink: `https://coast.noaa.gov/sealevelcalculator/`,
            gitLink: null,
            status: `in-production`,
            stackList: [
                `React`,
                `ArcGIS`,
                `OpenLayers`,
                `Highcharts`,
            ],
            role: 'Lead Frontend Developer',
            entryType: 'pro',
            initialImage: [
                slc1,
            ]
        },
        {
            title: `NOAA Lake Level Viewer`,
            tag: 'llv',
            description: `Showcased to the US Congress and other government bodies with great reception, this tool
                        is part of the inundation series of app's hosted by The Office for Coastal Management.
                        While leading the engineering team and mentoring a junior developer, we worked closely with a
                        physical sciences team to ensure maximum integrity and accuracy of the data and simulations.`,
            shortDescription: `This tool is part of the inundation series of app's hosted by The Office for Coastal 
                        Management, focusing specifically on The Great Lakes, and utilizes topographical maps to render flooding levels.`,
            listItems: [
                `Under federal directive we created a tool to simulate shore inundations of the US Great Lakes.`,
                `OpenLayers, Highcharts, and a pixel-rendering system display various water levels on a variety of charts and map types.`,
                `Publicly available for decision-making by city planners, residents, and civil engineers.`,
                `Link sharing and persistent state are provided through base-64 strings appended to the URL.`,
                `Dynamic application usage by third parties is enabled by water-level and location data controllable through the URL hash parameter.`,
                `A Social Vulnerability basemap retrieved from the CDC API, and a Depth Query feature to query multiple
                                points of water levels in the inundation zone or the shoreline.`
            ],
            liveLink: `https://coast.noaa.gov/llv/`,
            gitLink: null,
            status: `in-production`,
            stackList: [
                `React`,
                `ArcGIS`,
                `OpenLayers`,
                `HighCharts`,
            ],
            role: 'Lead Frontend Developer',
            entryType: 'pro',
            initialImage: [
                llv1,
            ],

        },
        {
            title: `NOAA Green Infrastructure Effectiveness Database`,
            tag: 'gi',
            description: `The database contains records from a wide range of sources including peer-reviewed journals, 
                        online tools, and gray literature, and provides information on 32 different coastal Green Infrastructure 
                        types. The application allows users to cross-reference queries from multiple categories with an extensive 
                        filtering and weighting system.`,
            shortDescription: `A front-end solution to display, store, and filter information on studies that pertain to Green Infrastructures, 
                        which are nature-based solution to coastal erosion, flooding, and ecosystem preservation.`,
            listItems: [
                `Literature on the effectiveness and economics of green infrastructure for coastal resilience`,
                `The ability to filter by hazard, green infrastructure technique, method (e.g., field measurements, modeling, economic analysis), literature source type, and geography`,
                `A summary, for each entry, with key findings, measures of effectiveness, co-benefits, and other helpful information`,
                `Links to the full literature resource and the ability to share results of a search with others`
            ],
            liveLink: `https://coast.noaa.gov/gisearch/#/`,
            gitLink: null,
            status: `in-production`,
            stackList: [
                `Angular 17`,
                `RxJS`,
                `TypeScript`,
            ],
            role: 'Frontend Developer',
            entryType: 'pro',
            initialImage: [
                gi1,
            ],

        },
        {
            title: 'NOAA Economic National Ocean Watch Explorer',
            tag: 'enow',
            description: `The NOAA Economic Ocean Watch Explorer (ENOW) displays data on all the coastal states and counties of the
                        Continental United States, as well as six US Territories. Spanning sectors from Tourism and Recreation,
                        to Offshore Mining, to Ship Building and Construction, ENOW allows users to gather comprehensive
                        data on their state, county, or territory. That same data is also served to the NOAA Quick Reports tool
                        that allows users to filter and download the data in csv formats.`,
            shortDescription: `The NOAA Economic Ocean Watch Explorer displays data on all coastal states and counties of the
                        Continental United States, and six US Territories. The data covers various aspects of marine economy, from tourism to ship-building.`,
            listItems: [
                `Spans six economic sectors and four data categories across 30 coastal states, 400+ marine counties over 16 years,
                and covers data on six US territories from the last three years.`,
                `Explore over 15 years of economic data for coastal communities, including 6 U.S. Territories.`,
                `HighCharts is used to display a variety of chart-types, giving users access to information in multiple formats.`,
                `The tool offers more than 165,000 unique data combinations, enabling tailored data exploration to suit diverse needs.`,
                `3 interactive charts and a statistics panel display each data combination; offering over 660,000 infographics to support
                                social and economic professionals understanding community dynamics.`,
            ],
            liveLink: `https://coast.noaa.gov/enowexplorer/`,
            gitLink: null,
            status: `in-production`,
            stackList: [
                `React`,
                `Highcharts`,
            ],
            role: 'Frontend Developer',
            entryType: 'pro',
            initialImage: [
                enow1,
            ],

        },
        {
            title: `Kloudlog`,
            tag: 'kl',
            description: `Kloudlog is a full stack React application that enables the user to develop, collaborate on, and template Terraform infrastructure as code modules. 
                        A custom drag-and-drop interface allows visual creation and editing of GCP resources. Visual layouts are translated into functional solutions - through 
                        nested resources and custom connectors. Allows teams to have an accessible single source of truth for their cloud architectures.`,
            shortDescription: `Principal engineer for full stack React application that enables the user to create, 
                        test, run, and collaborate on custom Terraform configurations and plans built in the Google Cloud Platform.`,
            listItems: [
                `Terraform infrastructure allows users to build and manage GCP resources within a visually intuitive environment.`,
                `A custom-built SVG drag-and-drop system enhances interactivity, with functional access-points to Redux actions at each stage of the drag-and-drop.`,
                `Persistent action history, visual customization options, and a change-log system similar to GitHub enhance user experience.`,
                `Multiple Context/Reducers make the app highly performant and extensible.`,
                `Websocket delivers real-time data updates, ensuring users always have the latest information at their fingertips.`,
                `Microservices architecture segregates application layers for optimal performance and scalability.`
            ],
            liveLink: null,
            gitLink: null,
            status: `in-development`,
            stackList: [
                `React`,
                `TypeScript`,
                `Terraform`,
                `Node`,
                `GCP`,
                `Firebase`
            ],
            role: 'Principal Engineer',
            entryType: 'pro',
            initialImage: [
                kl1
            ],

        },
        {
            title: `WyldGreens`,
            tag: 'wg',
            description: `Wyldgreens serves as a Landing, About, Product, and Order page in one, featuring parallax effects on desktop and a Colorado mountain
                        scene for brand identity. The client requested a unified presentation across all devices. I collaborated with a
                        UX designer/writer to make the copy instructive, compelling, and efficient to ensure no content loss on mobile.`,
            shortDescription: `Wyldgreens was built for a local business that serves its community with fresh and convienient deliveries of healthy food items. The site
                        features a full service website with product information, ordering, and confirmation on a single page, accessible on mobile.`,
            listItems: [
                `Nodemailer automatically responds to orders with customizable templates, streamlining business operations.`,
                `Custom CSS and JavaScript animations enhance user experience.`,
                `Responsive design provides the same user experience on any device.`,
            ],
            liveLink: `https://wyldgreens.herokuapp.com`,
            gitLink: `https://github.com/ashthomasweb/client--wyldgreens`,
            status: `client-maintained`,
            stackList: [
                `JavaScript`,
                `Node`,
                `Nodemailer`
            ],
            role: 'Full Stack Developer',
            entryType: 'pro',
            initialImage: [
                wyld1,
            ],

        },
        {
            title: `Loose Leash Dog`,
            tag: 'lld',
            description: `A vibrant website tailored for a sole proprietor whose business was previously confined to Instagram. This limited access to
                        potential clients and hindered further monetization opportunities. Using the LumberJack theme we seamlessly integrated
                        existing business traffic, increased link-through rates, and offered audiences access to a wealth of additional materials
                        and monetizable content- all on a single platform.`,
            shortDescription: `Built for a local sole proprietor whose business was restricted to Instagram. By utilizing the LumberJack template, the business-owner 
                        was able to bring their advertising and message further into the community, and increase monetization opportunities.`,
            listItems: [
                `Built with the Lumberjack reusable template, I was able to quickly bring the customer's branding and product offerings to a production site.`,
                `Showcases the business owners most recent Instagram photos and posts, with click through links, further connecting her audience.`,
                `Embracing mobile-friendly content creation, we empowered the business owner to craft content effortlessly
                                from any location with internet access, enhancing efficiency and flexibility.`,
                `Designed with 'friendly' elements that resonate with the essence of the business' mission statement,
                                cultivating a 'brand sense' that deeply connects with our target demographics.`
            ],
            liveLink: `https://looseleashdog.herokuapp.com`,
            gitLink: `https://github.com/ashthomasweb/client--looseleashdog.com`,
            status: `client-maintained`,
            stackList: [
                `JavaScript`,
                `Node`,
                `EJS`,
                `Prismic`,
                `Curator`
            ],
            role: 'Full Stack Developer',
            entryType: 'pro',
            initialImage: [
                lld1,
            ],

        },
        {
            title: `LumberJack`,
            tag: 'lj',
            description: `LumberJack is a Full Stack template developed for small businesses and social media creators.
                        Featuring a headless CMS, a multi-network aggregator, and automatic email responses thru
                        NodeMailer, maintenance needs and updates are low. This efficient build and 'on-the-go' content access gives clients more time to focus on what truly
                        matters, their business.`,
            shortDescription: `LumberJack is a Full Stack template developed for small businesses and social media creators.
                        Designed for developers to quickly take a customer's brand identity and build it into a fully functional
                        web platform.`,
            listItems: [
                `EJS and Prismic integrate to generate dynamic content, making blogging easy and accessible from anywhere the business owner happens to be.`,
                `Versatile card elements, which are modular and customizable, offer endless possibilities in terms of configuration, color schemes, and sizes.`,
                `Presented in a visually striking two-color brand scheme, complemented by highly legible greyscale.`,
                `NodeMailer automatically responds to customer orders, freeing up the business owner's valuable time.`,
                `Curator allows clients to effortlessly build feeds from multiple networks, fostering cross-network audience engagement and expanding their reach.`
            ],
            liveLink: `https://lumberjack-theme.herokuapp.com/`,
            gitLink: `https://github.com/ashthomasweb/template--lumberjack`,
            status: `in-production`,
            stackList: [
                `JavaScript`,
                `Node`,
                `EJS`,
                `Prismic`,
                `Curator`,
                `NodeMailer`
            ],
            role: 'Full Stack Developer',
            entryType: 'pro',
            initialImage: [
                lumber1,
            ],

        },

    ],
    personal: [
        {
            title: `CodeStasher`,
            tag: 'cs',
            description: `Codestasher offers a comprehensive solution for organizing and managing reusable code.
                        I built this app to help with my workflow, whether it's storing an entire component, a 
                        generic function, or instructive documentation. With a single click, users can swiftly 
                        copy their code to the clipboard, facilitating productivity and modularity in their workflow.`,
            shortDescription: `Codestasher offers a comprehensive solution for organizing and managing reusable code snippets.
                        Each entry is capable of housing multiple snippets designated to various files.`,
            listItems: [
                `Drag-and-drop feature allows seamless movement of entries between categories for optimal organization.`,
                `With four levels of nesting available, users can meticulously structure their code entries to suit their needs.`,
                `Ace Editor provides syntax highlighting and other essential editor functionalities to enhance coding precision and efficiency.`,
                `Supporting multiple languages within each entry, language specific syntax highlighting enhances user workflow.`,
                `Features a developer-friendly dark theme, similar to that of native VSCode.`
            ],
            liveLink: `https://codestasher.com`,
            gitLink: `https://github.com/ashthomasweb/devport`,
            status: `in-production`,
            stackList: [
                `React`,
                `Firebase`,
                `Ace Editor`
            ],
            entryType: 'pers',
            initialImage: [
                cs1,
            ],

        },
        {
            title: `Music Trainer & Generator`,
            tag: 'mt',
            description: `This is my first programming project and while the UI is undeveloped, the logic behind the arrangement generator 
                        is sound. I plan to build a proper UI and extend the code in the future, as well as add in a dynamically generated score. Music 
                        theory principles are applied to generate voice-led SATB arrangements based on user guidelines. Covering essential 
                        subjects such as melody, relative pitch, tempo, and the concept of "Klangfarbenmelodie"; the program 
                        aids students in honing their musical ear. `,
            shortDescription: `Leveraging JavaScript ES6, WebAudio API, and Garritan Instrument audio samples, this project delivers an infinite music
                        generator and a web-based ear-training platform tailored to classical music students.`,
            listItems: [
                `Featuring a mode that plays a single note across a randomized selection of orchestral instruments. This develops
                                the ear to be sensitive to pitch regardless of the instrument.`,
                `The arrangement generator has adjustments the user can make deciding what harmonies, key, length, repetitions,
                                and modal changes that can be made.`,
                `Future plans include a refactor of existing logic, a modern UI look and feel, and dynamically generated
                                musical score for viewing and download.`
            ],
            liveLink: `https://ashthomasweb.github.io/personal--music-trainer/`,
            gitLink: `https://github.com/ashthomasweb/personal--music-trainer`,
            status: `backlog`,
            stackList: [
                `JavaScript`,
                `WebAudio API`,
            ],
            entryType: 'pers',
            initialImage: [
                mt1,
            ],

        },
        {
            title: `Hal CLI`,
            tag: 'hal',
            description: `Built in Node using the Inquirer library, 'Hal' is a command line interface tool to store and create
                        templated builds for popular JS web libraries with a host of built-in features. Featuring an interactive CLI, 
                        the user can create fresh app builds. Inspired in part by the Angular CLI, I wanted a way to instantly create a
                        build with different services and structures ready to go.`,
            shortDescription: `Built in Node using the Inquirer library, 'Hal' is a command line interface tool to store and create
                        fresh React projects for popular build tools like Parcel, Vite, and Webpack.`,
            listItems: [
                `Published on npm and available as a global installation, user's can enhance their workflow in any project on their computer.`,
                `Reusable components can be manually loaded into a user's codebase. Minimal commenting allows for the file's import connections to be updated.`,
                `Future plans include, installable packages for popular libraries, single-line statements with flags,
                                and importing multiple components simultaneously.`,
            ],
            liveLink: `https://www.npmjs.com/package/@ashthomascode/hal`,
            gitLink: `https://github.com/ashthomasweb/component-library`,
            status: `in-testing`,
            stackList: [
                `Node`,
                `Inquirer`,
                `FS`
            ],
            entryType: 'pers',
            initialImage: [
                hal1,
            ],

        },
        {
            title: `Delta Trades`,
            tag: 'dt',
            description: `Delta trades analyzes change metrics on a requested stock, and flags buy or sell signals to cue transactions. This 
                    project is currently under development in collaboration with another engineer. The application uses multiple data sources, both for 
                    historic and real-time data. An Interface Pattern facilitates simultaneous connection to multiple Brokerage API connections,
                    for redundancy, future-proofing, and extensibility.`,
            shortDescription: `Delta Trades is a stock market analyzer and user interface powered by Tradier's API, currently under-development. 
                    It analyzes current market conditions and automatically places buy and sell transactions for the user.`,
            listItems: [
                `A Queue data structure and algorithm continuously analyzes the most recent 10 minutes of data in real-time.`,
                `A Node server runs 10 asynchronous modules in series to analyze, process, log, and store data and transactions.`,
                `The async coupling system runs the modules in series, allowing integration of middleware solutions.`,
                `A logger module continuously dispatches updates to an alert manager, resulting in storage within a database.`,
                `A React UI and candlestick-chart provide historical insights into the algorithm's performance, facilitating comprehensive testing and analysis.`
            ],
            liveLink: null,
            gitLink: null,
            status: `private`,
            stackList: [
                `React`,
                `Node`,
            ],
            entryType: 'pers',
            initialImage: [
                dt1,
            ],

        },
        {
            title: `Elephant Chart`,
            tag: 'ec',
            description: `Elephant Chart was built to help organize tasks, whether it's in life, or in an application design process. It was picked up as a proof-of-concept 
                        for my professional work at Kloudlog. It has a custom high-velocity drag-and-drop built in TypeScript React. Its skeumorphic drag-and-drop feature and ability 
                        to save custom charts and layouts, make for an intuitive and useful user experience.`,
            shortDescription: `I built Elephant Chart to aide in general organization tasks. It's useful for life-tasks as well as designing systems. A 
                        tray system that each item can display provides a dedicated space for supplementary information.`,
            listItems: [
                `React and Context API & Reducer provide a state-management system suitable for high-velocity DOM updates.`,
                `A Dynamic z-index layering system, allows the user to build a custom 'stack' of visual elements, with certain elements always 'in the back'.`,
                `Hosted in Firebase, secure authorization via Google or standard email addresses ensures privacy for all users.`,
                `A customizable backboard, note colors, and checkmark feature, help users stay productive and focused on their tasks.`
            ],
            liveLink: `https://elephantchart.com`,
            gitLink: `https://github.com/ashthomasweb/typescript--elephant-chart`,
            status: `Proof of Concept used by Kloudlog`,
            stackList: [
                `React`,
                `TypeScript`,
                `Node`,
                `Firebase`
            ],
            entryType: 'pers',
            initialImage: [
                ec1,
            ],

        },
        {
            title: `Info Stasher`,
            tag: 'is',
            description: `A tool for referencing pieces of information with the ability to build custom quizzes.`,
            shortDescription: `A tool for referencing pieces of information with the ability to build custom quizzes and cross-category 
                        tagging. Built using React and Firebase.`,
            listItems: [
                `Features an Interface Pattern with Firebase for storage`,
                `Organize by Set, Category, or Tags`,
                ``,
                ``
            ],
            liveLink: `infostasher.com`,
            gitLink: `github/infostasher`,
            status: `in-testing`,
            stackList: [
                `React`,
                `Firebase`,
            ],
            entryType: 'pers',
            initialImage: [
                is1,
            ],

        },

        {
            title: `uLog`,
            tag: 'ul',
            description: `uLog is a lightweight test module for JavaScript and Node. I built this to help myself understand the underlying mechanisms and needs of
                        testing processes. User efficiency is enhanced through color coded logs and helpful communication windows. The module can be run directly in
                        the code, or used to create individual test files.`,
            shortDescription: `uLog is a lightweight test package for JavaScript and Node. Asserts verification of function returns and logs test data to a .txt file
                        in the project directory.`,
            listItems: [
                `Categorization of test runs, addition of notes to test logs, and indication of expected failures with Pass/Fail display enhance user experience.`,
                `Color-coded logs and function definitions in the log output speed up the debugging process.`,
                `Planned features include an options object for customizable output, aimed at further improving user experience and flexibility.`,
            ],
            liveLink: null,
            gitLink: `https://github.com/ashthomasweb/js--tdd`,
            status: `archive`,
            stackList: [
                `Node`,
            ],
            entryType: 'pers',
            initialImage: [
                tdd1,
            ],

        },

    ]
}

export const supplementaryImages = {
    professional: [
        {
            title: `NOAA Sea Level Calculator`,
            images: [
                slc14,
                slc2,
                slc3,
                slc4,
                slc5,
                slc6,
                slc7,
                slc8,
                slc9,
                slc10,
                slc11,
                slc12,
                slc13
            ]
        },
        {
            title: `NOAA Lake Level Viewer`,
            images: [
                llv2,
                llv3,
                llv4,
                llv5,
                llv6,
                llv7,
                llv8,
                llv9,
                llv10,
                llv11,
                llv12,
                llv14,
            ]
        },
        {
            title: `NOAA Green Infrastructure Effectiveness Database`,
            images: [
                gi2,
                gi3,
                gi4,
                gi5
            ]
        },
        {
            title: `NOAA Economic National Ocean Watch Explorer`,
            images: [
                enow2,
                enow3,
                enow4,
                enow5,
                enow6
            ]
        },
        {
            title: `Kloudlog`,
            images: [
                null
            ]
        },
        {
            title: `WyldGreens`,
            images: [
                wyld2,
                wyld3,
                wyld4,
                wyld5,
                wyld6
            ]
        },
        {
            title: `Loose Leash Dog`,
            images: [
                lld2,
                lld3,
                lld4,
                lld5,
                lld6,
            ]
        },
        {
            title: `LumberJack`,
            images: [
                lumber2,
                lumber3,
                lumber4,
                lumber5,
                lumber6,
                lumber7,
            ]
        },
    ],
    personal: [
        {
            title: `CodeStasher`,
            images: [
                cs2,
                cs3,
                cs4,
                cs5
            ]
        },
        {
            title: `Music Trainer & Generator`,
            images: [
                mt2,
                mt3,
                mt4
            ]
        },
        {
            title: `Hal CLI`,
            images: [
                hal2,
                hal3,
                hal4
            ]
        },
        {
            title: `Delta Trades`,
            images: [
                dt2,
                dt3
            ]
        },
        {
            title: `Elephant Chart`,
            images: [
                ec2,
                ec3,
                ec4
            ]
        },
        {
            title: `Info Stasher`,
            images: [
                is2,
                is3,
                is4,
                is5,
                is6,
                is7
            ]
        },
        {
            title: `uLog`,
            images: [
                tdd2,
                tdd3
            ]
        },
    ]
}
