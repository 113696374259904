import { useState, useEffect, useContext } from 'react'

import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    supplementaryImages,
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 1
const file = 'ProjectGallery'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

import { Swiper, SwiperSlide } from '../../../node_modules/swiper/swiper-react.mjs'
import { Pagination } from 'swiper/modules'

export default function ProjectGallery({ entry, handleIndexChange, isActive }) {
    const {
        mainState: {
            professionalActive,
        }
    } = useContext(MainContext)

    const [contentArray, setContentArray] = useState()

    useEffect(() => {
        // trace(run) && log(...msg('UE'))
        if (isActive) {
            setTimeout(() => {
                const imageArray = supplementaryImages[professionalActive ? 'professional' : 'personal'].find(project => project.title === entry.title)
                setContentArray(imageArray)
            }, 1000)
        }
    }, [isActive])

    return (
        <div className='gallery-fade'>
            <Swiper
                direction={'horizontal'}
                slidesPerView={1}
                spaceBetween={125}
                mousewheel={false}
                centeredSlides={true}
                slideToClickedSlide={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className={`myGallery`}
            >
                {
                    entry.initialImage.map((image, index) => {
                        if (index === 0) {
                            return (
                                <SwiperSlide key={Math.ceil(Math.random() * 10e15)} >
                                    <div className='image-frame'>
                                        <div className='image-wrapper' style={{ background: `url(${entry.initialImage[index]})` }}></div>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    })
                }
                {
                    contentArray !== null
                        ? contentArray?.images.map((image, index) => {
                            if (index > 0) {
                                return (
                                    <SwiperSlide key={Math.ceil(Math.random() * 10e15)} >
                                        <div className='image-frame'>
                                            <div className='image-wrapper' style={{ background: `url(${contentArray.images[index]})` }}></div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        })
                        : null
                }
            </Swiper>
        </div>
    )
}