import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    DisplayService
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function SelectMenu() { // TODO: Refactor into generic component
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const handlePaneSelection = (e) => {
        DisplayService.setActivePane(e.target.value)
    }

    const tempStyles = {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }

    // TODO: handle default option value population on load

    return (
        <div style={tempStyles} >
            <select onChange={handlePaneSelection}>
                <option value='pane1'>Pane 1</option>
            </select>
        </div>
    )
}