import { useContext, useState } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    galleryConfig
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function HorizontalNav() {
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const [currentProject, setCurrentProject] = useState(null)

    const handleNavHover = ({ target }) => {
        document.getElementById(target.dataset.project).scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
        setCurrentProject(target.dataset.project)
        log(currentProject)
    }

    return (
        <div className={`horizontal-nav-container`} style={{position: 'absolute', left: '50%', transform: 'translate(-50%)' }}>
            <div className={`nav-bar`} style={{ width: `${galleryConfig.length * 30}px`, height: '100%', background: 'grey', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row' }}>
                {
                    galleryConfig.map((project, index) => {
                        return (
                            <button key={index} type='button' className={`nav-item ${project.title === currentProject ? 'active' : ''}`} style={{background: 'whitesmoke', borderRadius: '100%', padding: 0 }} data-project={project.title} onMouseEnter={handleNavHover} />
                        )
                    })
                }
            </div>
        </div>
    )
}