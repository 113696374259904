class DataPaths {
    base = {
        users: 'users',
    }

    extension = {
        
    }

    globalBasePath = null
    globalExtensionPath = null
}

export default DataPaths