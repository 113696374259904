// import { useContext, useState, useRef, useEffect } from 'react'
// import {
//     /* Firebase */
//     /* Components */
//     SVGIcon,
//     /* Context */
//     MainContext,
//     /* Views */
//     /* Custom Hooks */
//     /* Service Classes */
//     /* Utility Functions */
//     /* Assets */
//     /* Icons */
//     /* Configs */
//     /* Types */
//     /* Interfaces */
//     /* DeveloperTools */
//     debug,
//     logInit,
//     trace,
//     m
// } from '../../app-index'

// /* Trace vars */
// const run = 0
// const file = 'NEW'
// const msg = (copy, fileName = file) => m(copy, fileName)
// /* END Trace vars */

// export default function Gallery({ customClass, imageArray, width, height, outerPadding, innerPadding, buttonOnImage, buttonWidth, showDescription }) {
//     const {
//         mainState: {
//         }
//     } = useContext(MainContext)

//     const galleryContainerRef = useRef(null)
//     const imageWrapperRef = useRef(null)
//     const [entryIndex, setEntryIndex] = useState(0)
//     const [dynamicHeight, setDynamicHeight] = useState(null)
//     const [imageUrl, setImageUrl] = useState(null)

//     const propStyles = {
//         width,
//         height,
//     }

//     const controlImageContainerStyles = {
//         height: `${dynamicHeight}px`,
//         padding: `${outerPadding ? `${outerPadding}px` : 0}`,
//     }

//     const imageFrameStyles = {
//         padding: `${innerPadding ? `${innerPadding}px` : 0}`,
//     }

//     const leftButtonStyles = {
//         width: buttonWidth,
//         left: `${buttonOnImage
//                 ? 0
//                 : `${buttonWidth
//                     ? `-${buttonWidth}px`
//                     : '-30px'}`
//             }`
//     }

//     const rightButtonStyles = {
//         width: buttonWidth,
//         right: `${buttonOnImage
//                 ? 0
//                 : `${buttonWidth
//                     ? `-${buttonWidth}px`
//                     : '-30px'}`
//             }`
//     }

//     const backgroundImageStyles = {
//         background: imageUrl
//     }

//     useEffect(() => {
//         setDynamicHeight(parseInt(getComputedStyle(galleryContainerRef.current).width) / 16 * 9)
//     }, [dynamicHeight])
    
//     useEffect(() => {
//         setImageUrl(`url(${imageArray[entryIndex]})`)
//     }, [entryIndex])


//     const handleGalleryCycle = ({ target }) => {
//         if (target.dataset.direction === 'left') {
//             entryIndex === 0 ? setEntryIndex(imageArray.length - 1) : setEntryIndex(entryIndex - 1)
//         } else {
//             entryIndex === imageArray.length - 1 ? setEntryIndex(0) : setEntryIndex(entryIndex + 1)
//         }
//     }

//     return (
//         <div
//             className={`gallery-container ${customClass ? customClass : ''}`}
//             style={propStyles}
//             ref={galleryContainerRef}
//         >
//             <div className='background'>
//             </div>
//             <div className='controls-image-container' style={controlImageContainerStyles} >
//                 <button style={leftButtonStyles} className='left' type='button' data-direction='left' onClick={handleGalleryCycle}>
//                     <SVGIcon src={'chevronLeft'} fill='black' />
//                 </button>
//                 <div className='image-frame' style={imageFrameStyles}>
//                     <div ref={imageWrapperRef} className='image-wrapper' style={backgroundImageStyles}>
//                     </div>
//                 </div>
//                 <button style={rightButtonStyles} className='right' data-direction='right' type='button' onClick={handleGalleryCycle}>
//                     <SVGIcon src={'chevronRight'} fill='black' />
//                 </button>
//             </div>
//             {
//                 showDescription

//                     ?
//                     <div className='description-container'>
//                         <div className='entry-text'>
//                             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
//                                 ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
//                                 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
//                                 voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
//                                 cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//                         </div>
//                     </div>
//                     : null
//             }
//         </div>
//     )
// }