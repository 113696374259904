import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Name() {
    const {
        mainState: {
            projectEntryIsActive,
            contactActive
        }
    } = useContext(MainContext)

    return (
        <div className={`name-container ${projectEntryIsActive ? 'pushUp' : ''}`}>
            <span>Ash Thomas</span>
            <span>Fullstack JS/TS Engineer</span>
            <hr />
            <section className={`specialties-section ${contactActive ? 'slideOver' : ''}`}>
                <span style={{ fontSize: 14 }}>Specialist in:</span>
                <span style={{ fontSize: 14, marginLeft: 5 }}>React & Frontend</span>
                <span style={{ fontSize: 14, marginLeft: 5 }}>Data Visualization</span>
                <span style={{ fontSize: 14, marginLeft: 5 }}>Geographic Information Systems</span>
            </section>

        </div>
    )
}