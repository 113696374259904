import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function CardText({ copy, padding, width, height, backgroundHexColor, boxShadow, borderRadius, isGlass, blur, glassTransparencyDecimal }) {
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const hex2RBGA = (backgroundHexColor, glassTransparencyDecimal) => {
        const r = parseInt(backgroundHexColor.slice(1, 3), 16);
        const g = parseInt(backgroundHexColor.slice(3, 5), 16);
        const b = parseInt(backgroundHexColor.slice(5, 7), 16);
        const a = isGlass ? parseInt(glassTransparencyDecimal.toString().slice(2), 16) : 1

        const result = `rgba(${r}, ${g}, ${b}, 0.${a})`
        return result
    }

    const propStyles = {
        width,
        height,
        padding,
        backgroundColor: `${isGlass ? hex2RBGA(backgroundHexColor, glassTransparencyDecimal) : backgroundHexColor}`,
        boxShadow,
        borderRadius,
        backdropFilter: `${blur ? `blur(${blur}px)` : 'none'}`,
        WebkitBackdropFilter: `${blur ? `blur(${blur}px)` : 'none'}`
    }

    return (
        <div className='card-text-container' style={propStyles}>
            <p>
                {copy}
            </p>
        </div>
    )
}