import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Container({ position, width, height, ...props}) {
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const propStyles = {
        position,
        width,
        height
    }

    return (
        <div className='container' style={propStyles}>
            {props.children}
        </div>
    )
}