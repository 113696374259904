import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function FullBackground({backgroundSize, backgroundPosition, width, zoom}) {
    const {
        mainState: {
        }
    } = useContext(MainContext)

    const propStyles = {
        backgroundSize,
        backgroundPosition,
        width: width ? `${(width * 250)}px` : '100%',
        zoom: zoom ? `${zoom}%` : 'none'
    }

    return (
        <div className='full-background-container' style={propStyles}>
        </div>
    )
}